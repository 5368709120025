<template>
  <div>
    <dv-scroll-board
      v-if="flag"
      :config="config"
      style="width: 95%; height: 23vh; margin: 1vh 0 0 2.5%"
    />
  </div>
</template>
<script>
import { mapState, mapMutations } from "vuex";
import Mock from "mockjs";
export default {
  name: "LeftEchartOne",
  data() {
    return {
      flag: false, // 是否加载完数据
      config: {
        header: ["城市", "减排量(万t)"],
        data: [],
      },
    };
  },
  computed: {
    ...mapState(["val"]),
  },
  watch: {
    val() {
      this.initPage();
    },
  },
  mounted() {
    this.initPage();
  },
  methods: {
    ...mapMutations(["updatePage"]),
    initPage() {
      this.flag = false;
      setTimeout(() => {
        const list = Mock.mock({
          "list|12": [["@city()", "@integer(-200, -50)"]],
        });
        this.config.data = list.list;
        this.flag = true;
      });
    },
  },
};
</script>
