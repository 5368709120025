<template>
  <div>
    <div
      id="echart-two"
      style="width: 95%; height: 23vh; margin: 1vh 0 0 2.5%"
    ></div>
  </div>
</template>
<script>
import { mapState, mapMutations } from "vuex";
import Mock from "mockjs";
export default {
  name: "LeftEchartOne",
  data() {
    return {};
  },
  computed: {
    ...mapState(["val"]),
  },
  watch: {
    val() {
      this.initPage();
    },
  },
  mounted() {
    this.initPage();
  },
  methods: {
    ...mapMutations(["updatePage"]),
    initPage() {
      const listcity = Mock.mock({
        "list|12": ["@city()"],
      });
      const list = Mock.mock({
        "list|12": ["@integer(120, 200)"],
      });
      const list1 = Mock.mock({
        "list|12": ["@integer(120, 200)"],
      });

      this.$nextTick(() => {
        var myChart = echarts.init(document.getElementById("echart-two"));
        let option = {
          title: {
            subtext: "（t）",
            subtextStyle: {
              color: "#ffffff",
            },
          },
          grid: {
            bottom: "1vh",
          },
          tooltip: {
            trigger: "axis",
          },
          // legend: {
          //   data: ["Rainfall", "Evaporation"],
          // },
          toolbox: {
            show: true,
            feature: {
              dataView: { show: true, readOnly: false },
              magicType: { show: true, type: ["line", "bar"] },
              restore: { show: true },
              saveAsImage: { show: true },
            },
            iconStyle: {
              color: "#ffffff",
            },
          },
          calculable: true,
          xAxis: [
            {
              type: "category",
              data: listcity.list,
            },
          ],
          yAxis: {
            type: "value",
            nameTextStyle: {
              color: "#ffffff",
              fontStyle:30
            },
          },
          textStyle:{
            color:"#ffffff"
          },
          series: [
            {
              name: "CO₂排放量",
              type: "bar",
              data: list.list,
              // markPoint: {
              //   data: [
              //     { type: "max", name: "Max" },
              //     { type: "min", name: "Min" },
              //   ],
              // },
              // markLine: {
              //   data: [{ type: "average", name: "Avg" }],
              // },
            },
            {
              name: "CH₄排放量",
              type: "bar",
              data: list1.list,
              // markPoint: {
              //   data: [
              //     { name: "Max", value: 182.2, xAxis: 7, yAxis: 183 },
              //     { name: "Min", value: 2.3, xAxis: 11, yAxis: 3 },
              //   ],
              // },
              // markLine: {
              //   data: [{ type: "average", name: "Avg" }],
              // },
            },
          ],
        };
        myChart.setOption(option);
      });
    },
  },
};
</script>
