import Mock from "mockjs";

Mock.mock("/api/user/login", "post", {
  // 返回数据
  code: 200,
  msg: "登录成功",
  data: {
    token: "1234567890",
    user: {
      id: 1,
      name: "admin",
      email: "admin@qq.com",
      role: "admin",
    },
  },
});

const list = Mock.mock({
  "list|6": [
    {
      name: "@city()",
      "value|30-200": 10,
    },
  ],
});
Mock.mock("/api/paimingdata", "get", {
  // 返回数据
  code: 200,
  msg: "success",
  data: list,
});
