import { createApp } from "vue";
import App from "./App.vue";
import dataV from "@jiaminghi/data-view";
import store from "./store";
// import Mock from 'mockjs';
import './mockjs'

if(process.env.NODE_ENV === 'development'){
    // Mock.start();
}

const app = createApp(App);
app.use(store);
app.use(dataV);
app.mount("#app");
