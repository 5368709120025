<template>
  <div style="padding: 1vh 0 0 2%">
    <dv-conical-column-chart
      v-if="flag"
      :config="config"
      style="width: 95%; height: 23vh"
    />
  </div>
</template>
<script>
// import axios from "axios";
import { mapState, mapMutations } from "vuex";
import Mock from "mockjs";
export default {
  name: "LeftEchartOne",
  data() {
    return {
      flag: false,
      config: {
        data: [],
        img: [
          require('@/assets/1st.png'),
          require('@/assets/2st.png'),
          require('@/assets/3st.png'),
          require('@/assets/4st.png'),
          require('@/assets/5st.png'),
          require('@/assets/6st.png'),
          require('@/assets/7st.png'),
        ],
        showValue: true,
      },
    };
  },
  computed: {
    ...mapState(["val"]),
  },
  watch: {
    val() {
      this.initPage();
    },
  },
  mounted() {
    this.initPage();
  },
  methods: {
    ...mapMutations(["updatePage"]),
    initPage() {
      this.flag = false;
      setTimeout(() => {
        const list = Mock.mock({
          "list|6": [
            {
              name: "@city()",
              "value|30-200": 10,
            },
          ],
        });
        this.config.data = list.list;
        this.config.data = [
          {name: "2023年", value: 560},
          {name: "2022年", value: 430},
          {name: "2021年", value: 380},
          {name: "2020年", value: 250},
          {name: "2019年", value: 220},
          {name: "2018年", value: 150},
        ]
        this.flag = true;
      });

      // axios.get("/api/paimingdata").then((res) => {
      //   this.config.data = res.data.data.list;
      //   this.flag = true;
      // });
    },
  },
};
</script>
