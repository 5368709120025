<template>
  <div id="mountNode">
    <h1>China</h1>
    <div id="china"></div>
    <div class="provinceBox">
      <div id="province"></div>
    </div>
  </div>
</template>

<script>
import {mapMutations} from 'vuex'
export default {
  name: "ChartLine",
  props: {
    msg: String,
  },
  data() {
    return {
      provinceName: "",
      province: "",
      dataList: [
        { name: "南海诸岛" },
        { ename: "beijing", name: "北京" },
        { ename: "tianjin", name: "天津" },
        { ename: "shanghai", name: "上海" },
        { ename: "chongqing", name: "重庆" },
        { ename: "hebei", name: "河北" },
        { ename: "henan", name: "河南" },
        { ename: "yunnan", name: "云南" },
        { ename: "liaoning", name: "辽宁" },
        { ename: "heilongjiang", name: "黑龙江" },
        { ename: "hunan", name: "湖南" },
        { ename: "anhui", name: "安徽" },
        { ename: "shandong", name: "山东" },
        { ename: "xinjiang", name: "新疆" },
        { ename: "jiangsu", name: "江苏" },
        { ename: "zhejiang", name: "浙江" },
        { ename: "jiangxi", name: "江西" },
        { ename: "hubei", name: "湖北" },
        { ename: "guangxi", name: "广西" },
        { ename: "gansu", name: "甘肃" },
        { ename: "shanxi", name: "山西" },
        { ename: "neimenggu", name: "内蒙古" },
        { ename: "shanxi1", name: "陕西" },
        { ename: "jilin", name: "吉林" },
        { ename: "fujian", name: "福建" },
        { ename: "guizhou", name: "贵州" },
        { ename: "guangdong", name: "广东" },
        { ename: "qinghai", name: "青海" },
        { ename: "xizang", name: "西藏" },
        { ename: "sichuan", name: "四川" },
        { ename: "ningxia", name: "宁夏" },
        { ename: "hainan", name: "海南" },
        { name: "台湾" },
        { ename: "xianggang", name: "香港" },
        { ename: "aomen", name: "澳门" },
      ],
    };
  },
  mounted() {
    this.initEchart();
  },
  methods: {
    ...mapMutations(['updatePage']),
    initEchart() {
      let dataList = this.dataList;
      for (let i = 0; i < dataList.length; i++) {
        dataList[i].value = Math.ceil(Math.random() * 1000 - 1);
      }
      const _this = this;
      var myChart = echarts.init(document.getElementById("china"));
      var option = {
        tooltip: {
          //数据格式化
          formatter: function (params) {
            return (
              params.seriesName + "<br />" + params.name + "：" + params.value
            );
          },
        },
        visualMap: {
          min: 0,
          max: 1000,
          left: "left",
          top: "bottom",
          text: ["高", "低"], //取值范围的文字
          inRange: {
            color: ["#e0ffff", "blue"], //取值范围的颜色
          },
          show: true, //图注
        },
        geo: {
          map: "china", //引入地图数据
          roam: false, //不开启缩放和平移
          zoom: 1.3, //视角缩放比例
          label: {
            normal: {
              show: true,
              fontSize: "10",
              color: "rgba(0,0,0,0.7)",
            },
          },
          itemStyle: {
            normal: {
              borderColor: "rgba(0, 0, 0, 0.2)",
            },
            emphasis: {
              //高亮的显示设置
              areaColor: "skyblue", //鼠标选择区域颜色
              shadowOffsetX: 0,
              shadowOffsetY: 0,
              shadowBlur: 20,
              borderWidth: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        },
        // 鼠标悬浮提示框
        series: [
          {
            name: "省份",
            type: "map",
            geoIndex: 0,
            data: _this.dataList,
          },
        ],
      };
      myChart.setOption(option);
      _this.provinceName = "hubei";
      _this.province = "湖北";
      _this.initSheng();
      myChart.on("click", function (params) {
        if (!params.data.ename) {
          alert("暂无" + params.name + "地图数据");
          return;
        }
        _this.provinceName = params.data.ename;
        _this.province = params.name;
        _this.initSheng();
        _this.updatePage()
      });
    },
    initSheng() {
      let echartObj = null;
      let option = {
        title: {
          text: this.province,
          top: "8%",
          left: "49.5%",
          textStyle: {
            fontSize: 20,
            fontWeight: 300,
            color: "#b6d7ff",
          },
        },
        tooltip: {
          padding: 2,
          // backgroundColor: "transparent",
          // 数据格式化
          formatter: function (params) {
            return params.name + "：" + params.dataIndex;
          },
        },
        // legend: {
        //   orient: "vertical",
        //   top: "9%",
        //   left: "5%",
        //   icon: "circle",
        //   data: [],
        //   selectedMode: "single",
        //   selected: {},
        //   itemWidth: 12,
        //   itemHeight: 12,
        //   itemGap: 30,
        //   inactiveColor: "#b6d7ff",
        //   textStyle: {
        //     color: "#ec808d",
        //     fontSize: 14,
        //     fontWeight: 300,
        //     padding: [0, 0, 0, 15],
        //   },
        // },
        // visualMap: {
        //   min: 0,
        //   max: 500,
        //   left: "left",
        //   top: "bottom",
        //   text: ["高", "低"], // 取值范围的文字
        //   inRange: {
        //     color: ["#e0ffff", "blue"], // 取值范围的颜色
        //   },
        //   show: true, // 图注
        // },
        geo: {
          map: "",
          roam: false, // 不开启缩放和平移
          zoom: 0.6, // 视角缩放比例
          label: {
            normal: {
              show: true,
              fontSize: 12,
              color: "#ffffff", // 填充颜色
            },
            emphasis: {
              show: true,
              color: "#ffffff", // 填充颜色
            },
          },
          itemStyle: {
            normal: {
              areaColor: {
                type: "radial",
                x: 0.5,
                y: 0.5,
                r: 0.5,
                colorStops: [
                  {
                    offset: 0,
                    color: "rgba(147, 235, 248, 0)", // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "rgba(147, 235, 248, .2)", // 50% 处的颜色
                  },
                ],
              },
              borderColor: "rgba(147, 235, 248)",
              borderWidth: 1,
            },
            emphasis: {
              areaColor: "#389BB7",
              borderWidt: 0,
            },
          },
          //对某一个地区设置特殊样式
          // regions: [
          //   {
          //     name: "宜昌市",
          //     itemStyle: {
          //       areaColor: "blue",
          //       color: "red",
          //     },
          //   },
          // ],
          left: "5%",
          right: "5%",
          top: "5%",
          bottom: "0%",
        },
        series: [
          {
            name: "年度总项目数据查询",
            type: "map",
            geoIndex: 0, // 不可缺少，否则无tooltip 指示效果
            data: [],
          },
        ],
        provinceJSON: {},
        provinceName: "",
      };
      const provinceName = this.provinceName;
      const province = this.province;
      this.provinceJSON = require("../../utils/省份数据/json(省份)/" +
        provinceName);
      option.geo.map = province;
      echartObj = echarts.init(document.getElementById("province"));
      echarts.registerMap(province, this.provinceJSON);
      echartObj.setOption(option);
      window.addEventListener("resize", () => {
        if (echartObj && echartObj.resize) {
          echartObj.resize();
        }
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
#mountNode {
  height: 100%;
  position: relative;
  > h1 {
    position: absolute;
    top: 0.5vh;
    left: 50%;
    margin: 0;
    color: #ffffff;
    font-size: 2vh;
    font-weight: normal;
    text-align: center;
  }

  #china {
    position: absolute;
    left: 3%;
    right: 3%;
    top: 4vh;
    height: 40vh;
  }
  .provinceBox {
    position: absolute;
    left: 3%;
    right: 3%;
    top: 45vh;
    height: 38vh;
    text-align: center;
    display: flex;
    align-items: center;
    // border: 1px solid red;
  }
  #province {
    height: 38vh;
    width: 100%;
    // border: 1px solid red;
  }
}
</style>
