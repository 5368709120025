import { createStore } from "vuex";

export default createStore({
  state: {
    val: 0,
  },
  getters: {},
  mutations: {
    updatePage(state) {
      state.val++;
    },
  },
  actions: {},
  modules: {},
});
