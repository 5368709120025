<template>
  <div>
    <dv-scroll-ranking-board
      :config="config"
      style="width: 95%; height: 23vh; margin:1vh 0 0 2.5%; color: '#fff'"
    />
  </div>
</template>
<script>
export default {
  name: "LeftEchartOne", 
  
  data() {
    return {
      config: {
        data: [
          {
            name: "发电",
            value: 55,
          },
          {
            name: "石化",
            value: 120,
          },
          {
            name: "化工",
            value: 78,
          },
          {
            name: "建材",
            value: 66,
          },
          {
            name: "钢铁",
            value: 80,
          },
          {
            name: "有色金属",
            value: 45,
          },
          {
            name: "造纸",
            value: 105,
          },
        ]
      },
    };
  },
  mounted() {},
};
</script>
<style>
  .dv-scroll-ranking-board > .ranking-info > .rank{
  color: '#ffffff' !important;
}
</style>
