<template>
  <div>
    <div
      id="echart-two1"
      style="width: 95%; height: 23vh; margin: 1vh 0 0 2.5%"
    ></div>
  </div>
</template>
<script>
import { mapState, mapMutations } from "vuex";
import Mock from "mockjs";
export default {
  name: "LeftEchartOne",
  data() {
    return {
      
    };
  },
  computed: {
    ...mapState(["val"]),

  },
  watch: {
    val() {
      this.initPage();
    },
  },
  mounted() {
    this.initPage();
  },
  methods: {
    ...mapMutations(["updatePage"]),
    initPage() {
      const list = Mock.mock({
        "list|6": ["@integer(5000, 10000)"],
      });
      // const list1 = Mock.mock({
      //   "list|6": ["@integer(5000, 10000)"],
      // });
      this.$nextTick(() => {
        let myChart = echarts.init(document.getElementById("echart-two1"));
        let option = {
          title: {
            // text: "Basic Radar Chart",
          },
          legend: {
            data: ["Allocated Budget", "Actual Spending"],
            textStyle: {
              color: "#ffffff",
            },
          },
          radar: {
            // shape: 'circle',
            indicator: [ //太阳能、地热能、核能、风能、海洋能、生物质能
              { name: "太阳能", max: 10000 },
              { name: "地热能", max: 16000 },
              { name: "核能", max: 15000 },
              { name: "风能", max: 15000 },
              { name: "海洋能", max: 13000 },
              { name: "生物质能", max: 14000 },
            ],
          },
          textStyle: {
            color: "#ffffff",
          },
          series: [
            {
              name: "Budget vs spending",
              type: "radar",
              data: [
                {
                  value: list.list,
                  // name: "Allocated Budget",
                },
                // {
                //   value: list1.list,
                //   // name: "Actual Spending",
                // },
              ],
            },
          ],
        };
        myChart.setOption(option);
      });
    },
  },
};
</script>
