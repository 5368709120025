<template>
  <div class="bgouter">
    <div class="title-box">
      <img src="@/assets/tzsklogoWhite.png" alt="" />
      <dv-border-box-1 class="dv-border-box-1"
        >碳排放及能源大数据展示</dv-border-box-1
      >
      <dv-border-box-8 class="dv-border-box-8">{{ datetime }}</dv-border-box-8>
    </div>
    <div class="model-math">
      <dv-border-box-12>
        <div class="title-name">能源消耗总量（万吨标煤/年）</div>
        <div class="number">783</div>
      </dv-border-box-12>
      <dv-border-box-12>
        <div class="title-name">碳排放总量（万吨/CO₂年）</div>
        <div class="number">86</div>
      </dv-border-box-12>
      <dv-border-box-12>
        <div class="title-name">能耗强度（吨标煤/万元GDP）</div>
        <div class="number">100</div>
      </dv-border-box-12>
      <dv-border-box-12>
        <div class="title-name">社会降碳贡献（万吨CO₂/年）</div>
        <div class="number">1.55</div>
      </dv-border-box-12>
    </div>
    <div class="outer-box">
      <div class="left">
        <div class="chart-box">
          <h1>社会降碳贡献(万tCO₂)</h1>
          <LeftEchartOne></LeftEchartOne>
        </div>
        <div class="chart-box">
          <h1>不同城市CO₂和CH₄排放量</h1>
          <LeftEchartTwo></LeftEchartTwo>
        </div>
        <div class="chart-box">
          <h1>不同城市降碳数据</h1>
          <LeftEchartThree></LeftEchartThree>
        </div>
      </div>
      <div class="center">
        <dv-border-box-9><EchartEarth /></dv-border-box-9>
      </div>
      <div class="right">
        <div class="chart-box">
          <h1>不同燃料二氧化碳和甲烷排放量</h1>
          <RightEchartOne></RightEchartOne>
        </div>
        <div class="chart-box">
          <h1>不同城市能源结构趋势图</h1>
          <RightEchartTwo></RightEchartTwo>
        </div>
        <div class="chart-box">
          <h1>行业碳排放量</h1>
          <RightEchartThree></RightEchartThree>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EchartEarth from "./components/echarts/EchartEarth.vue";
import LeftEchartOne from "./components/leftEchart/LeftEchartOne.vue";
import LeftEchartTwo from "./components/leftEchart/LeftEchartTwo.vue";
import LeftEchartThree from "./components/leftEchart/LeftEchartThree.vue";
import RightEchartOne from "./components/rightEchart/RightEchartOne.vue";
import RightEchartTwo from "./components/rightEchart/RightEchartTwo.vue";
import RightEchartThree from "./components/rightEchart/RightEchartThree.vue";

export default {
  name: "App",
  components: {
    EchartEarth,
    LeftEchartOne,
    LeftEchartTwo,
    LeftEchartThree,
    RightEchartOne,
    RightEchartTwo,
    RightEchartThree,
  },
  data() {
    return {
      datetime: "",
    };
  },
  mounted() {
    setInterval(() => {
      this.getDate();
    }, 1000);
  },
  methods: {
    getDate() {
      let date = new Date();
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      let hour = date.getHours();
      let minute = date.getMinutes();
      let second = date.getSeconds();
      let secondval = second < 10 ? "0" + second : second;
      this.datetime =
        year +
        "-" +
        month +
        "-" +
        day +
        " " +
        hour +
        ":" +
        minute +
        ":" +
        secondval;
    },
  },
};
</script>

<style scoped lang="less">
.bgouter {
  background: url('@/assets/bg3.png') no-repeat;
  background-size: 100% 100%;
}
.title-box {
  height: 8vh;
  display: flex;
  justify-content: center;
  // border: 1px solid #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  > img {
    margin-left: 2%;
    height: 5vh;
  }
  .dv-border-box-1 {
    height: 7vh !important;
    line-height: 7vh;
    width: 450px !important;
    color: #ffffff;
    font-size: 2.5vh;
    text-align: center;
    letter-spacing: 2px;
  }
  .dv-border-box-8 {
    color: #ffffff;
    font-size: 1.5vh;
    height: 5vh !important;
    line-height: 5vh;
    width: 250px !important;
    text-align: center;
    margin-right: 1%;
  }
}

.model-math {
  height: 8vh;
  // border: 1px solid white;
  display: flex;
  align-items: center;
  justify-content: space-around;
  .dv-border-box-12 {
    width: 20%;
    height: 6.5vh;
  }
  .title-name {
    font-size: 1.5vh;
    color: #ffffff;
    text-align: center;
    margin-top: 1.3vh;
    margin-bottom: 0.3vh;
  }
  .number {
    font-size: 1.2vh;
    color: #ffffff;
    text-align: center;
  }
}

.outer-box {
  height: 84vh;
  display: flex;
  justify-content: space-between;
  // border: 1px solid #ffffff;

  .left {
    // border: 1px solid #ffffff;
    width: 25%;
  }
  .right {
    /* border: 1px solid #ffffff; */
    width: 25%;
  }
  .center {
    width: 50%;
  }
}
.chart-box {
  text-align: center;
  height: 28vh;
  // border: 1px solid #ffffff;
  > h1 {
    height: 3vh;
    margin: 0 10% 0 2.5%;
    line-height: 3vh;
    text-align: left;
    padding-left: 3%;
    font-size: 1.5vh;
    color: #ffffff;
    font-weight: normal;
    border: 1px solid #222;
    background-image: linear-gradient(to right, #2e367a, rgb(32, 34, 54));
  }
}
</style>
