<template>
  <div>
    <div
      id="echart-line"
      style="width: 95%; height: 23vh; margin: 1vh 0 0 2.5%"
    ></div>
  </div>
</template>
<script>
import { mapState, mapMutations } from "vuex";
import Mock from "mockjs";
export default {
  name: "LeftEchartOne",
  data() {
    return {};
  },
  computed: {
    ...mapState(["val"]),
  },
  watch: {
    val() {
      this.initPage();
    },
  },
  mounted() {
    this.initPage();
  },
  methods: {
    ...mapMutations(["updatePage"]),
    initPage() {
      const list = Mock.mock({
        "list|8": ["@integer(50, 100)"],
      });
      const list1 = Mock.mock({
        "list|8": ["@integer(50, 80)"],
      });
      this.$nextTick(() => {
        var myChart = echarts.init(document.getElementById("echart-line"));
        let option = {
          title: {
            // text: "Stacked Area Chart",
          },
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "cross",
              label: {
                backgroundColor: "#6a7985",
              },
            },
          },
          legend: {
            // data: [
            //   "二氧化碳",
            //   "Union Ads",
            //   "Video Ads",
            //   "Direct",
            //   "Search Engine",
            // ],
            textStyle: {
              color: "#ffffff",
            },
          },
          // toolbox: {
          //   feature: {
          //     saveAsImage: {},
          //   },
          //   iconStyle: {
          //     color: "#ffffff",
          //   },
          // },
          textStyle: {
            color: "#ffffff",
          },
          grid: {
            left: "3%",
            right: "4%",
            bottom: "3%",
            containLabel: true,
          },
          xAxis: [
            {
              type: "category",
              boundaryGap: false,
              data: [
                "无烟煤",
                "烟煤",
                "褐煤",
                "洗精煤",
                "型煤",
                "焦炭",
                "原油",
                "燃料油",
              ],
            },
          ],
          yAxis: [
            {
              type: "value",
            },
          ],
          series: [
            {
              name: "二氧化碳排放量",
              type: "line",
              stack: "Total",
              areaStyle: {},
              emphasis: {
                focus: "series",
              },
              data: list.list,
            },
            {
              name: "甲烷排放量",
              type: "line",
              stack: "Total",
              areaStyle: {},
              emphasis: {
                focus: "series",
              },
              data: list1.list,
            },
            // {
            //   name: "其它",
            //   type: "line",
            //   stack: "Total",
            //   areaStyle: {},
            //   emphasis: {
            //     focus: "series",
            //   },
            //   data: list2.list,
            // },
            // {
            //   name: "Direct",
            //   type: "line",
            //   stack: "Total",
            //   areaStyle: {},
            //   emphasis: {
            //     focus: "series",
            //   },
            //   data: list3.list,
            // },
            // {
            //   name: "Search Engine",
            //   type: "line",
            //   stack: "Total",
            //   label: {
            //     show: true,
            //     position: "top",
            //   },
            //   areaStyle: {},
            //   emphasis: {
            //     focus: "series",
            //   },
            //   data: list4.list,
            // },
          ],
        };
        myChart.setOption(option);
      });
    },
  },
};
</script>
